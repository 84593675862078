/* HOW TO USE:
<% if user_signed_in? %>
  <button 
    data-is-following="<%= transaction.politician.followed_by?(current_user) %>"
    data-politician-id="<%= transaction.politician.id %>"
    data-location="politician-list"
    class="follow-btn js-follow-politician <%= 'follow--is-following' if transaction.politician.followed_by?(current_user) %>"
  >
    <span class="follow--show-if-following">Following</span>
    <span class="follow--hide-if-following">+ Follow</span>
  </button>
<% end %> */

const ready = require('./utilities').ready;
const getParent = require('./utilities').getParent;
const fetchApiUrl = require('./utilities').fetchApiUrl;

ready(init);

function init() {
  document.addEventListener('click', onClick);
}

function onClick(event) {
  const element = getParent(event.target, 'js-follow-politician');
  if (!element) return;
  
  const wantsToFollow = !(element.dataset.isFollowing === 'true');

  element.classList.toggle('follow--is-following', wantsToFollow);
  element.setAttribute('data-is-following', wantsToFollow);

  const body = {
    politician_id: element.dataset.politicianId,
    follow: element.dataset.isFollowing,
  };
  fetchApiUrl(
    '/api/v1/follow_politician',
    wantsToFollow ? 'POST' : 'DELETE',
    JSON.stringify(body)
  );

  const category = wantsToFollow ? 'follow_politician' : 'unfollow_politician';
  if (typeof gtag === 'function') {
    gtag('event', category, {
      'politician_id': element.dataset.politicianId,
      'event_label': element.dataset.location
    });
  }
} 